.kt-menu__nav {
  margin: 0;
  list-style: none;
  padding: 15px 0;
  color: "#4c4e6f" !important;
  font-size: "0.9rem";
}

.kt-menu__section {
  margin-bottom: 25px;
  width: 100%;
  padding: 0 12px 0 14px;
}

.menu_section-text {
  color: "#4c4e6f" !important;
  font-size: "0.9rem";
  font-weight: 500;
  text-transform: "uppercase";
  letter-spacing: "0.3px";
}

.kt-menu__section:hover {
  background-color: #dadce0;
  width: 100%;
  padding: 5px;
  border-radius: 0 16px 16px 0;
}
