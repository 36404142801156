.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -100px !important;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}
