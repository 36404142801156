.tabs {
  position: relative;
  width: 100%;
}

.tabs nav {
  text-align: center;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  padding: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  -ms-box-pack: center;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.tabs nav a {
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2.5;
  font-weight: 300;
  font-size: 1.25em;
  padding: 0px 6px 0px 6px;
}

.tabs nav a span {
  font-size: 0.75em;
}

.tabs nav .tab-current a {
  color: #000;
}

.tabs nav a:focus {
  outline: none;
}

@media screen and (max-width: 58em) {
  .tabs nav a.icon span {
    display: none;
  }
  .tabs nav a:before {
    margin-right: 0;
  }
}

.tabs-style-bar nav {
  background: rgba(40, 44, 42, 0.05);
  min-width: "200px";
}

.tabs-style-bar nav {
  border: 4px solid transparent;
}

.tabs-style-bar nav a {
  margin-right: 2px;
  margin-bottom: 2px !important;
  background-color: #f7f7f7;
  color: #74777b;
  transition: background-color 0.2s, color 0.2s;
}

.tabs-style-bar nav a:hover,
.tabs-style-bar nav a:focus {
  color: #5867dd;
}

.tabs-style-bar nav a.active {
  background: #061f2b !important;
  color: #fff !important;
}
